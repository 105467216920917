import React from "react";
import styles from "./Spinner.module.css";
import cx from "../../services/utils/cx";

const Spinner = (p: {width?: number, height?: number}) => {
    return (
        <div className={styles.container}>
            <div className={styles.contentCentered}>
                <div className={cx(styles.spinner, !p.width ? styles.spinnerSuspense : styles.spinnerLoading)}
                     style={{width: p.width || 36, height: p.height || 36}} />
            </div>
        </div>
    )
};

export default Spinner;