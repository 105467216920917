import Logger from "../services/utils/logger";

// export default function useSettingsHook() {
//
//     const init = (keys: any, settings: any) => {
//         Logger.debug("<<<<<=====USER SETTINGS INIT=======>>>>");
//         return new Promise((resolve) => {
//             for (const i in keys) {
//                 const value: any = settings[i];
//                 if (value) keys[i] = value;
//                 // const value: any = localStorage.getItem(i);
//                 // keys[i] = CustomerHelper.isJsonString(value) ? JSON.parse(value) : value;
//             }
//             resolve(keys);
//         });
//     };
//
//     const setSetting = (key: any, value: any) => {
//         localStorage.setItem(key, value);
//     };
//
//     const removeSetting = (key: any) => {
//         localStorage.removeItem(key);
//     };
//     return { setSetting, removeSetting, init };
// }

export default {

    init (keys: any, settings: any) {
        Logger.debug("<<<<<=====USER SETTINGS INIT=======>>>>");
        return new Promise((resolve) => {
            for (const i in keys) {
                const value: any = settings[i];
                if (value) keys[i] = value;
                // const value: any = localStorage.getItem(i);
                // keys[i] = CustomerHelper.isJsonString(value) ? JSON.parse(value) : value;
            }
            resolve(keys);
        });
    },

    setSetting (key: any, value: any) {
        localStorage.setItem(key, value);
    },

    removeSetting (key: any) {
        localStorage.removeItem(key);
    }
}