import { UserToken } from "./interface";
import { itemLocalStorage } from "./constants";
import useSettingsHook from "../hooks/Settings";

export class userToken implements UserToken {
    private _token: any = null; // token
    private _expiration: any = null;  // expiration
    private _privileges: string[] = [];  // privileges
    // private settingsHook: any = useSettingsHook();

    private updateStorage() {
        const userToken: UserToken = {
            token: this._token,
            expiration: this._expiration,
            privileges: this._privileges,
        };
        useSettingsHook.setSetting(itemLocalStorage.userToken, JSON.stringify(userToken));
    }

    public set token(value: any) {
        this._token = value;
        this.updateStorage();
    }

    public get token(): any {
        return this._token;
    }
    public set expiration(value: any) {
        this._expiration = value;
        this.updateStorage();
    }

    public get expiration(): any {
        return this._expiration;
    }
    public set privileges(value: string[]) {
        this._privileges = value;
        this.updateStorage();
    }

    public get privileges(): string[] {
        return this._privileges;
    }
}