import {MapInteraction} from "./interface";
import {StorageService} from "./storageService";
import {IVehicleTrack} from "../models/IVehicleTrack";

export class mapInteraction {
    private _checkedVehiclesIds: number [];
    private _checkedVehiclesTempIds: number [];
    private _checkedTracks: IVehicleTrack [];
    private _checkedGeofencesIds: number [];
    private _checkedVehiclesIdsTails: number [];
    private _followVehicleId: number | undefined;

    constructor(mi?: MapInteraction) {
        this._checkedVehiclesIds = mi?.checkedVehiclesIds || [];
        this._checkedVehiclesTempIds = mi?.checkedVehiclesTempIds || [];
        this._checkedTracks = mi?.checkedTracks || [];
        this._checkedGeofencesIds = mi?.checkedGeofencesIds || [];
        this._checkedVehiclesIdsTails = mi?.checkedVehiclesIdsTails || [];
        this._followVehicleId = mi?.followVehicleId || undefined;
    }

    private updateStorage() {
        const mapInteraction: MapInteraction = {
            checkedVehiclesIds: this._checkedVehiclesIds,
            checkedVehiclesTempIds: this._checkedVehiclesTempIds,
            checkedTracks: this._checkedTracks,
            checkedGeofencesIds: this._checkedGeofencesIds,
            checkedVehiclesIdsTails: this._checkedVehiclesIdsTails,
            followVehicleId: this._followVehicleId
        };
        StorageService.changeMapInteraction('localStorage', mapInteraction);
    }

    public set checkedVehiclesIds(values: any []) {
        this._checkedVehiclesIds = values;
        this.updateStorage();
    }

    public get checkedVehiclesIds(): number [] {
        return this._checkedVehiclesIds;
    }

    public set checkedVehiclesTempIds(values: any []) {
        this._checkedVehiclesIds = values;
        this.updateStorage();
    }

    public set checkedTracks(values: IVehicleTrack []) {
        this._checkedTracks = values;
        this.updateStorage();
    }

    public get checkedTracks(): IVehicleTrack [] {
        return this._checkedTracks;
    }

    public set checkedGeofencesIds(values: any []) {
        this._checkedGeofencesIds = values;
        this.updateStorage();
    }

    public get checkedGeofencesIds(): number [] {
        return this._checkedGeofencesIds;
    }

    public set checkedVehiclesIdsTails(values: number []) {
        this._checkedVehiclesIdsTails = values;
        this.updateStorage();
    }

    public get checkedVehiclesIdsTails(): number [] {
        return this._checkedVehiclesIdsTails;
    }

    public set followVehicleId(value: number | undefined) {
        this._followVehicleId = value;
        this.updateStorage();
    }

    public get followVehicleId(): number | undefined {
        return this._followVehicleId;
    }
}