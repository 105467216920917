import * as styledComponents from 'styled-components';

export const theme = {
    default: {
        primary: '#fff',
        componentBackground: '#fff',
        componentBackgroundSecondary: '#fff',
    },
};
const {
    default: styled,
    css,
    createGlobalStyle,
    keyframes,
    ThemeProvider,
} = styledComponents;

export { css, createGlobalStyle, keyframes, ThemeProvider };
export default styled;