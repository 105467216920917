export default class Logger {
	/**
	 * @param {string} file
	 * @param {any} log
	*/

	static debug(file: string, ...log: any) {
		if (process.env.NODE_ENV === 'production') return;
		if (log && log.length > 0) {
			// eslint-disable-next-line no-console
			console.debug(`[${file}]`, log);
		} else {
			// eslint-disable-next-line no-console
			console.debug(file);
		}
	}

	/**
	* @param {string} file
	* @param {any} log
	*/

	static warning(file: string, log: any) {
		// eslint-disable-next-line no-console
		console.warn(`[${file}]`, log);
	}

	/**
	* @param {string} file
	* @param {any} log
	*/

	static error(file: string, log: any) {
		const err = log.error ? log.error : log;
		// eslint-disable-next-line no-console
		console.error(`[${file}]`, err);
	}
}
