
export class LazyLoadingHelper {
    public static lazyReloadOnFail(fn: any): Promise<any> {
        return new Promise((resolve/*, reject*/) => {
            fn()
                .then(resolve)
                .catch(() => {
                    // reject(new Error('No internet connection'));
                    // window.location.href = "/page-404";
                    window.location.reload();
                });
        });
    }
}