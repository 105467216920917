import {MapSettings} from "./interface";
import {StorageService} from "./storageService";

export class mapSettings {
    private _layer: string;// APP_MAP_LAYER
    private _zoom: number;// APP_MAP_ZOOM
    private _center: any;// APP_MAP_CENTER
    private _geoZones: boolean;// APP_MAP_GEO_ZONES
    // private _objectTail: boolean; // APP_MAP_OBJECT_TRAIL
    private _objectName: boolean; // APP_MAP_OBJECT_NAME
    private _objectFollow: boolean; // FOLLOWED_OBJECT
    private _roadsTraffic: boolean;// APP_MAP_ROADS_TRAFFIC

    constructor(ms?: MapSettings) {
        this._layer = ms ? ms.layer : 'OSM';
        this._zoom = ms ? ms.zoom : 15;
        this._center = ms?.center || JSON.stringify({ lat: 50.4481, lng: 30.5260 });
        this._geoZones = ms ? ms.geoZones : false;
        // this._objectTail = ms ? ms.objectTail : false;
        this._objectName = ms ? ms.objectName : false;
        this._objectFollow = ms ? ms.objectFollow : false
        this._roadsTraffic = ms ? ms.roadsTraffic : false;
    }

    private updateStorage() {
        const mapSettings: MapSettings = {
            layer: this._layer,
            zoom: this._zoom,
            center: this._center,
            geoZones: this._geoZones,
            // objectTail: this._objectTail,
            objectName: this._objectName,
            objectFollow: this._objectFollow,
            roadsTraffic: this._roadsTraffic,
        };
        StorageService.changeMapSettings('API', mapSettings);
    }

    public set layer(value: string) {
        this._layer = value;
        this.updateStorage();
    }

    public get layer(): string {
        return this._layer;
    }
    public set zoom(value: number) {
        this._zoom = value;
        this.updateStorage();
    }

    public get zoom(): number {
        return this._zoom;
    }
    public set center(value: any) {
        this._center = value;
        this.updateStorage();
    }

    public get center(): any {
        return this._center;
    }

    public setCenterZoomLayer(center: any, zoom: number, layer: string) {
        this._center = center;
        this._zoom = zoom;
        this._layer = layer;
        this.updateStorage();
    }

    public set geoZones(value: boolean) {
        this._geoZones = value;
        this.updateStorage();
    }

    public get geoZones(): boolean {
        return this._geoZones;
    }
    // public set objectTail(value: boolean) {
    //     this._objectTail = value;
    //     this.updateStorage();
    // }

    // public get objectTail(): boolean {
    //     return this._objectTail;
    // }
    public set objectName(value: boolean) {
        this._objectName = value;
        this.updateStorage();
    }

    public get objectName(): boolean {
        return this._objectName;
    }

    public set objectFollow(value: boolean) {
        this._objectFollow = value;
        this.updateStorage();
    }
    public get objectFollow(): boolean {
        return this._objectFollow;
    }

    public set roadsTraffic(value: boolean) {
        this._roadsTraffic = value;
        this.updateStorage();
    }

    public get roadsTraffic(): boolean {
        return this._roadsTraffic;
    }
}