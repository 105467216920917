import { UiInterface } from "./interface";
import {StorageService} from "./storageService";

export class uiInterface {
    private _openMenu: boolean;
    private _violationsNotification: boolean;
    private _emptyGroups: boolean;
    private _showOwnGeozones: boolean;

    constructor(ui?: UiInterface) {
        this._openMenu = ui ? ui.openMenu : true;
        this._violationsNotification = ui ? ui.violationsNotification : false;
        this._emptyGroups = ui ? ui.emptyGroups : false;
        this._showOwnGeozones = ui ? ui.showOwnGeozones : false;
    }

    private updateStorage() {
        const appUi: UiInterface = {
            openMenu: this._openMenu,
            violationsNotification: this._violationsNotification,
            emptyGroups: this._emptyGroups,
            showOwnGeozones: this._showOwnGeozones,
        };
        StorageService.changeUIInterfaceSettings('API', appUi)
    }

    public set openMenu(value: boolean) {
        this._openMenu = value;
        this.updateStorage();
    }

    public get openMenu(): boolean {
        return this._openMenu;
    }
    public set violationsNotification(value: boolean) {
        this._violationsNotification = value;
        this.updateStorage();
    }

    public get violationsNotification(): boolean {
        return this._violationsNotification;
    }
    public set emptyGroups(value: boolean) {
        this._emptyGroups = value;
        this.updateStorage();
    }

    public get emptyGroups(): boolean {
        return this._emptyGroups;
    }
    public set showOwnGeozones(value: boolean) {
        this._showOwnGeozones = value;
        this.updateStorage();
    }

    public get showOwnGeozones(): boolean {
        return this._showOwnGeozones;
    }
}