import IconCopy from "../../assets/icons/actions/copy.svg";
import IconPdf from "../../assets/icons/actions/pdf.svg";
import IconPrint from "../../assets/icons/actions/print.svg";

export const menuRightTabs = {
    user: [
        { label: "account_settings.main", value: "main", content: "tabUser" },
        { label: "settings.access", value: "access", content: "tabUserAccess" },
    ],
    driver: [],
    object: [
        { label: "account_settings.main", value: "main", content: "tabVehicleEdit" },
        { label: "settings.object.technical_parameters", value: "technical", content: "tabVehicleTechnical" },
        { label: "settings.access.sensors", value: "sensors", content: 'tabVehicleSensor' },
        { label: "settings.access.sensor_visualisation", value: "visualisation", content: 'tabVehicleSensorVisualisation' },
        // { label: "settings.access.maintenance", value: "maintenance", content: '' },
    ],
    tracker: [
        { label: "settings.device.settings", value: "main", content: "tabDeviceEdit" },
        { label: "settings.device.commands", value: "commands", content: "tabDeviceCommands" },
    ],
    account: [],
    events: [
        // { label: "settings.adjustment.events_table", value: "main", content: "tabEventsTable" },
        // { label: "settings.adjustment.notifications_channels", value: "channels", content: "tabChannels" },
    ],
    channels: [],
} as const;

export const menuLeftTabs = [
    {
        label: "settings.user",
        value: "user",
        menuRightTab: menuRightTabs['user'][0].value,
        content: "tabUser",
        privilege: "user.view"
    },
    {
        label: "settings.driver",
        value: "driver",
        content: "tabDriver",
        menuRightTab: "driver.add",
        privilege: "driver.view"
    },
    {
        label: "settings.object",
        value: "object",
        content: "tabObject",
        menuRightTab: menuRightTabs['object'][0].value,
        privilege: "vehicle.view"
    },
    {
        label: "settings.tracker",
        value: "tracker",
        content: "tabDevice",
        menuRightTab: menuRightTabs['tracker'][0].value,
        privilege: "device.view"
    },
    {
        label: "settings.adjustment",
        value: "adjustment",
        content: "tabAdjustment",
        menuRightTab: "",
        privilege: undefined //"maintenance.view"
    },
] as const;

export const sortControlBlockDrivers = [
    { id: 'name', name: 'sort.alphabetically', checked: true },
    { id: 'created_at', name: 'sort.by_time_of_entry', checked: false },
];

export const sortControlBlock = [
    { id: 'is_super', name: 'sort.alphabetically', checked: true },
    { id: 'created_at', name: 'sort.by_time_of_entry', checked: false },
];

export const tableRightView = [
    { id: "administration", name: "settings.access.administration", code: "admin", privileges: {} },
    { id: "monitoring", name: "settings.access.monitoring", code: "monitor", privileges: {} },
    { id: "trekking", name: "settings.access.trekking", code: "track", privileges: {} },
    { id: "geozone", name: "settings.access.geozone", code: "geofence", privileges: {} },
    { id: "logistics", name: "settings.access.logistics", code: "logistic", privileges: {} },
    { id: "settings", name: "settings.access.settings", code: "setup", privileges: {} },
    { id: "sensors", name: "settings.access.sensors", code: "sensors", privileges: {} },
    { id: "reporting", name: "settings.access.reporting", code: "report", privileges: {} },
    { id: "maintenance", name: "settings.access.maintenance", code: "maintenance", privileges: {} },
    // { id: "billing", name: "settings.access.billing", code: "billing", privileges: {} },
    { id: "alarm", name: "settings.access.alarm", code: "alarm", privileges: {} },
];

export const tableRightChange = [
    { id: "add_edit_users", name: "settings.access.add_edit_users", parentCode: "module.setup", privileges: {}, codes: ["user.view", "user.edit", "user.del"], disabledCodes: [], module: "setup" },
    { id: "add_edit_drivers", name: "settings.access.add_edit_drivers", parentCode: "module.setup", privileges: {}, codes: ["driver.view", "driver.edit", "driver.del"], disabledCodes: [], module: "setup" },
    { id: "add_edit_objects", name: "settings.access.add_edit_objects", parentCode: "module.setup", privileges: {}, codes: ["vehicle.view", "vehicle.edit", "vehicle.del"], disabledCodes: [], module: "setup" },
    { id: "add_edit_geozones", name: "settings.access.add_edit_geozones", parentCode: "module.geofence", privileges: {}, codes: ["geofence.view", "geofence.edit", "geofence.del"], disabledCodes: [], module: "geofence" },
    { id: "add_edit_trips", name: "settings.access.add_edit_trips", parentCode: "module.logistic", privileges: {}, codes: ["route.view", "route.edit", "route.del"], disabledCodes: [], module: "logistic" },
    { id: "add_edit_sensors", name: "settings.access.add_edit_sensors", parentCode: "module.setup", privileges: {}, codes: ["sensor.view", "sensor.edit", "sensor.del"], disabledCodes: [], module: "setup" },
    { id: "add_edit_groups", name: "settings.access.add_edit_groups", parentCode: "module.setup", privileges: {}, codes: ["group.view", "group.edit", "group.del"], disabledCodes: [], module: "setup" },
    { id: "add_edit_maintenance", name: "settings.access.add_edit_maintenance", parentCode: "module.maintenance", privileges: {}, codes: ["maintenance.view", "maintenance.edit", "maintenance.del"], disabledCodes: [], module: "maintenance" },
    { id: "add_edit_reports", name: "settings.access.add_edit_reports", parentCode: "module.report", privileges: {}, codes: ["report.view", "report.edit", "report.del"], disabledCodes: [], module: "report" },
    { id: "add_edit_commands", name: "settings.access.add_edit_commands", parentCode: "module.setup", privileges: {}, codes: ["command.view", "command.edit", "command.del"], disabledCodes: [], module: "setup" },
    { id: "add_edit_trackers", name: "settings.access.add_edit_trackers", parentCode: "module.setup", privileges: {}, codes: ["device.view", "device.edit", "device.del"], disabledCodes: [], module: "setup" },
    { id: "add_edit_customers", name: "settings.access.add_edit_customers", parentCode: "module.admin", privileges: {}, codes: ["customer.view", "customer.edit", "customer.del"], disabledCodes: [], module: "admin" },
    { id: "add_edit_tracks", name: "settings.access.add_edit_tracks", parentCode: "module.track", privileges: {}, codes: ["track.view", "track.edit", "track.del"], disabledCodes: [], module: "track" },
    { id: "add_edit_tariffs", name: "settings.access.add_edit_tariff_plans", parentCode: "module.admin", privileges: {}, codes: ["tariff.view", "tariff.edit", "tariff.del"], disabledCodes: [], module: "admin" },
    { id: "add_edit_events", name: "settings.access.add_edit_events", parentCode: "module.setup", privileges: {}, codes: ["event.view", "event.edit", "event.del"], disabledCodes: [], module: "setup" },
];

export const tableRightElementTrack = [
    { id: "over_speed", name: "settings.access.over_speed", privileges: {}, codes: ["track.overspeed"] },
    { id: "geofence_violation", name: "settings.access.geofence_violation", privileges: {}, codes: ["track.geofence.violation"] },
    { id: "gas_stations", name: "settings.access.gas_stations", privileges: {}, codes: ["track.refueling"] },
    { id: "fuel_drains", name: "settings.access.fuel_drains", privileges: {}, codes: ["track.fueldrain"] },
    { id: "stops_parking", name: "settings.access.stops_parking", privileges: {}, codes: ["track.stops"] },
];

export const tableRightControl = [
    { id: "sending_commands", name: "settings.access.sending_commands", privileges: {}, codes: ["command.send"] },
    { id: "communication_object", name: "settings.access.communication_object", privileges: {}, codes: ["vehicle.voice"] },
    { id: "viewing_video_object", name: "settings.access.viewing_video_object", privileges: {}, codes: ["vehicle.video"] },
    { id: "view_account_information", name: "settings.access.view_account_information", privileges: {}, codes: ["customer.edit_self"] },
    { id: "view_vehicle_all", name: "settings.access.view_vehicle_all", privileges: {}, codes: ["vehicle.view_all"] },

];

export const moreHeaderBlock = [
    { id: 'copy', name: 'button.copy', icon: IconCopy },
    { id: 'exportPdf', name: 'button.export_pdf', icon: IconPdf },
    { id: 'print', name: 'button.print', icon: IconPrint }
];