import {FormatNameObject} from "./interface";
import {StorageService} from "./storageService";

export class formatNameObject {
    private _name: boolean;
    private _model: boolean;
    private _license: boolean;
    private _brand: boolean;

    constructor(format?: FormatNameObject) {
        this._name = format ? format.name : true;
        this._model = format ? format.model : false;
        this._license = format ? format.license : false;
        this._brand = format ? format.brand : false;
    }

    private updateStorage() {
        const appFormat: FormatNameObject = {
            name: this._name,
            model: this._model,
            license: this._license,
            brand: this._brand,
        };
        StorageService.changeFormatNameObjectSettings('API', appFormat)
    }

    public set name(value: boolean) {
        this._name = value;
        this.updateStorage();
    }

    public get name(): boolean {
        return this._name;
    }
    public set model(value: boolean) {
        this._model = value;
        this.updateStorage();
    }

    public get model(): boolean {
        return this._model;
    }
    public set license(value: boolean) {
        this._license = value;
        this.updateStorage();
    }

    public get license(): boolean {
        return this._license;
    }
    public set brand(value: boolean) {
        this._brand = value;
        this.updateStorage();
    }

    public get brand(): boolean {
        return this._brand;
    }
}