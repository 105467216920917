import {StorageService} from "../../settings/storageService";

export default {
    getUserPermissions() {
        const modules = StorageService.getAccessModules()/*localStorage.getItem('modules')*/;
        return modules ? JSON.parse(modules) : [];
    },

    isAvailablePermission(allowedPermissions: string []) {
        let visible = true;
        let modules = StorageService.getAccessModules()/*localStorage.getItem('modules')*/;
        if (modules) {
            modules = JSON.parse(modules);
            allowedPermissions.forEach((permission) => {
                if (modules && !modules.includes(permission)) {
                    visible = false;
                }
            });
        }
        return visible;
    },

    getUserPrivileges() {
        const privileges = StorageService.getAccessPrivileges()/*localStorage.getItem('privileges')*/;
        return privileges ? JSON.parse(privileges) : [];
    },

    isAvailablePrivilege(allowedPrivileges: string []) {
        let visible = true;
        let privileges = StorageService.getAccessPrivileges()/*localStorage.getItem('privileges')*/;
        if (privileges) {
            privileges = JSON.parse(privileges);
            allowedPrivileges.forEach((privilege) => {
                if (privileges && !privileges.includes(privilege)) {
                    visible = false;
                }
            });
        }
        return visible;
    }
}