import { UserToken } from "./interface";
import { uiInterface } from "./uiInterface";
import { formatNameObject } from "./formatNameObject"
import { mapSettings } from "./mapSettings";
import { userToken } from "./userToken";
import useSettingsHook from "../hooks/Settings";
import {userLanguage} from "./language";
import {mapInteraction} from "./mapInteraction";
import { Vehicle } from "../models/Vehicle";
import { Geofence } from "../models/Geofence";

class userSettings {
    private _userLang: userLanguage;
    private _formatNameObject: formatNameObject;
    private _uiInterface: uiInterface;
    private _mapSettings: mapSettings | undefined;
    private _mapInteraction: mapInteraction | undefined;
    private _userToken: UserToken;
    // private settingsHook: any = useSettingsHook();
    constructor() {
        this._userLang = new userLanguage();
        this._formatNameObject = new formatNameObject();
        this._uiInterface = new uiInterface();
        // this._mapSettings = new mapSettings();
        // this._mapInteraction = undefined; new mapInteraction();
        this._userToken = new userToken();
    }

    public async init(id: number, settings: any, language: string) {
        this._userLang = new userLanguage(language);
        await this.parseAPISettings(settings);
        // await this.parseLocalStorageSettings(id);
    }

    private parseAPISettings = async (settings: any) => {
        const keys: any = {
            "APP_FORMAT": null,
            "USER_TOKEN": null,
            "APP_MAP": null,
            "APP_UI": null
        };
        const values: any = await useSettingsHook.init(keys, settings);
        for (const i in keys) {
            switch (i) {
                case "APP_FORMAT":
                    if (values[i]) {
                        this._formatNameObject = new formatNameObject(values[i]);
                    }
                    break;
                case "USER_TOKEN":
                    if (values[i]) {
                        this._userToken = values[i];
                    }
                    break;
                case "APP_MAP":
                    this._mapSettings = new mapSettings(values[i]);
                    // if (values[i]) {
                    //     this._mapSettings = new mapSettings(values[i]);
                    // }
                    break;
                case "APP_UI":
                    if (values[i]) {
                        this._uiInterface = new uiInterface(values[i]);
                    }
                    break;
            }
        }
    };

    public parseLocalStorageSettings = (id: number) => {
        const data = localStorage.getItem(`APP_MAP_INTERACTION_USER_${id}`);
        if (data) this._mapInteraction = new mapInteraction(JSON.parse(data));
        else this._mapInteraction = /*undefined; */new mapInteraction();
    };

    public updateCheckedVehicles = (vehicles: Vehicle []) => {
        if (this.mapInteraction) {
            const ids = vehicles.filter((vehicle: Vehicle) => vehicle.checked).map((vehicle: Vehicle) => vehicle.id);
            if (ids?.length !== this.mapInteraction.checkedVehiclesIds?.length) {
                this.mapInteraction.checkedVehiclesIds = ids;
            }
        }
    }

    public updateCheckedGeofences = (geofences: Geofence []) => {
        if (this.mapInteraction) {
            const ids = geofences.filter((geofence: Geofence) => geofence.checked).map((geofence: Geofence) => geofence.id);
            if (ids?.length !== this.mapInteraction.checkedGeofencesIds?.length) {
                this.mapInteraction.checkedGeofencesIds = ids;
            }
        }
    }

    public get userLang(): userLanguage {
        return this._userLang;
    }
    public set userLang(value: userLanguage) {
        this._userLang = value;
    }
    public get formatNameObject(): formatNameObject {
        return this._formatNameObject;
    }

    public get uiInterface(): uiInterface {
        return this._uiInterface;
    }

    public get mapSettings(): mapSettings | undefined {
        return this._mapSettings;
    }

    public get mapInteraction(): mapInteraction | undefined {
        return this._mapInteraction;
    }
}
const settings = new userSettings();
export { settings };