enum ActionTypes {
    SET_LOADING = "reports/SET_LOADING",

    SWITCH_LEFT_MENU_ITEM = "reports/SWITCH_LEFT_MENU_ITEM",
    SWITCH_REPORTS_FORMAT = "reports/SWITCH_REPORTS_FORMAT",
    SWITCH_RIGHT_MENU_ITEM = "reports/SWITCH_RIGHT_MENU_ITEM",
    GET_LIST_DRIVER = "reports/GET_LIST_DRIVER",
    SET_DRIVERS = "reports/SET_DRIVERS",
    SET_REPORT_VEHICLES = "reports/SET_REPORT_VEHICLES",
    UPDATE_REPORT_VEHICLE = "reports/UPDATE_REPORT_VEHICLE",
    SET_DATE_FRAME = "reports/SET_DATE_FRAME",
    SET_REPORT_TYPES = "reports/SET_REPORT_TYPES",
    SET_SELECTED_DRIVERS = "reports/SET_SELECTED_DRIVERS",
    GET_REPORTS_LIST = "reports/GET_REPORTS_LIST",
    SET_REPORTS_LIST = "reports/SET_REPORTS_LIST",
    GET_GRAPH_DATA = "reports/GET_GRAPH_DATA",
    SET_GRAPH_TYPES = "reports/SET_GRAPH_TYPES",
    SET_GRAPH_LIST = "reports/SET_GRAPH_LIST",
    SET_IS_OPEN_INFO_MODAL = "reports/SET_IS_OPEN_INFO_MODAL",
    SET_IS_BUILDING_REPORTS = "reports/SET_IS_BUILDING_REPORTS",
    REMOVE_REPORTS_ITEM_BY_VALUE = "reports/REMOVE_REPORTS_ITEM_BY_VALUE",

    ADD_REPORT_PARAMS_TO_MAP = "reports/ADD_REPORT_PARAMS_TO_MAP",
    SET_PDF_REPORT_PARAMS = "reports/SET_PDF_REPORT_PARAMS",
    GET_PDF_REPORT = "reports/GET_PDF_REPORT",

    GET_PERIODIC_REPORTS = "reports/GET_PERIODIC_REPORTS",
    SET_PERIODIC_REPORTS = "reports/SET_PERIODIC_REPORTS",
    CREATE_UPDATE_PERIODIC_REPORT = "reports/CREATE_UPDATE_PERIODIC_REPORT",
    SET_PERIODIC_REPORT_TYPES = "reports/SET_PERIODIC_REPORT_TYPES",
    SET_PERIODIC_GRAPH_TYPES = "reports/SET_PERIODIC_GRAPH_TYPES",
    SET_REPORT_CRUD_MODE = "reports/SET_REPORT_CRUD_MODE",
    DELETE_PERIODIC_REPORT = "reports/DELETE_PERIODIC_REPORT",
    ENABLE_PERIODIC_REPORT = "reports/ENABLE_PERIODIC_REPORT",
    DISABLE_PERIODIC_REPORT = "reports/DISABLE_PERIODIC_REPORT",
    SET_CHART_JOIN = "reports/SET_CHART_JOIN"
}

export default ActionTypes;