import {StorageService} from "./storageService";
import LanguageService from "../services/lang/LanguageService";

export class userLanguage {
    private _lang: string;

    constructor(lang?: any) {
        this._lang = lang ? lang : LanguageService.getDefaultLanguage()/* 'uk'*/;
    }

    private updateStorage() {
        StorageService.changeLanguageSettings('API', this._lang);
    }

    public set language(lang: string) {
        this._lang = lang;
        this.updateStorage();
    }

    public get language(): string {
        return this._lang;
    }
}